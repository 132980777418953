.magic-form-loader {
	text-align: center;
}

.magic-form {
	ul {
		list-style: disc;
		padding-inline-start: 40px;
	}
}
