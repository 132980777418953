/**
 * Global
 */

.container {
	max-width: 960px;
	width: 100%;
	margin: 0 auto;
	padding: 0 10px;

	&::after,
	&::before {
		content: ' ';
		display: table;
	}

	&::after {
		clear: both;
	}
}

.section-container {
	padding-top: 50px;
	padding-bottom: 50px;

	@media all and (max-width: 767px) {
		padding-top: 30px;
		padding-bottom: 30px;
	}
}

.section-heading {
	margin-top: 20px;
	margin-bottom: 50px;
	text-transform: uppercase;
	text-align: center;
	color: var(--titlevariant);

	&::after {
		content: '';
		display: block;
		max-width: 140px;
		width: 100%;
		margin: 30px auto 0;
		border-bottom: 2px solid var(--titlevariant);
	}

	@media all and (max-width: 767px) {
		margin-bottom: 30px;

		&::after {
			margin-top: 20px;
		}
	}
}

.all-elements {
	margin-top: 50px;
	text-align: center;

	@media all and (max-width: 767px) {
		margin-top: 30px;
	}
}

.list-inline {
	font-size: 0;

	&.list-left {
		text-align: left;
	}

	&.list-center {
		text-align: center;
	}

	&.list-right {
		text-align: right;
	}

	> li {
		display: inline-block;
		font-size: 1rem;

		@media all and (max-width: 767px) {
			width: 100%;
			margin-bottom: 20px;
			padding: 0 0 20px;
			border-bottom: 1px solid rgba(0, 0, 0, 0.2);

			&:last-child {
				padding-bottom: 0;
				margin-bottom: 0;
				border-bottom: none;
			}
		}
	}
}

.list-pills {
	margin-left: -10px;
	margin-right: -10px;

	@media all and (max-width: 767px) {
		margin-left: 0;
		margin-right: 0;
	}

	> li {
		margin-left: 10px;
		margin-right: 10px;
		margin-bottom: 20px;
		padding: 0;

		@media all and (max-width: 767px) {
			margin-left: 0;
			margin-right: 0;
			border-bottom: none;
		}

		> a {
			display: block;
			border: 1px solid var(--iy-fog-light);
			border-radius: 4px;
			padding: 15px 25px;
			transition: background-color 0.2s ease-out;
			font-weight: 500;

			&:hover {
				text-decoration: none;
				background-color: var(--iy-fog-light);
			}

			i {
				font-size: 30px;
				margin-right: 15px;
				vertical-align: middle;
			}
		}
	}
}
