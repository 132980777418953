.admin-ui {
	--bgvariant: #fff;
	--linkvariant: #2e3c4d;
	--btnbgvariant: #2e3c4d;
	--btntextvariant: #fff;
	--textvariant: #333;

	color: var(--textvariant);

	--blue: #475e7f;

	/* Bootstrap colors */
	--primary-color: #2e3c4d;
	--info-color: #5790e0;
	--success-color: #93b100;
	--warning-color: #ff7200;
	--error-color: #d71d2a;
	--inactive-color: #bbb;
	--gray-base: #000;
	--gray-darker: #222;
	--gray-dark: #333;
	--gray: #555;
	--gray-light: #777;
	--gray-lighter: #eee;

	h4 {
		font-size: 18px;
		font-weight: normal;
	}

	legend {
		color: var(--primary-color);
		font-weight: bold;
	}

	.modal-content {
		display: flex;
		flex-direction: column;
		max-height: calc(100vh - 80px);
		border-radius: 0;
		border: none;
	}

	.modal-header {
		padding: 16px 20px;
		background-color: var(--blue);
		color: white;
		border: 0;

		.close {
			color: #fff;
			text-shadow: 0 1px 0 #000;
			opacity: 0.5;

			&:hover,
			&:focus {
				opacity: 0.9;
			}
		}
	}

	/** Buttons **/
	@keyframes spin {
		from {
			transform: rotate(0deg);
		}

		to {
			transform: rotate(360deg);
		}
	}

	.btn-loading::before {
		content: '';
		display: inline-flex;
		border-radius: 1em;
		width: 1em;
		height: 1em;
		margin-right: 10px;
		border: 2px solid rgba(255, 255, 255, 0.4);
		border-top-color: #fff;
		animation: spin 1s linear infinite;
	}

	/**
	 * Bootstrap custom
	 */
	.btn {
		padding: 16px 20px;
		background-color: transparent;

		&:hover,
		&.focus,
		&:focus {
			background-color: var(--gray-lighter);
			background-color: rgba(0, 0, 0, 0.05);
			color: var(--primary-color);
		}
	}

	.btn-default {
		color: var(--primary-color);
		background-color: #fff;

		&:hover,
		&.focus,
		&:focus,
		&:active,
		&:active:hover,
		&:active:focus {
			color: var(--primary-color);
			background-color: var(--gray-lighter);
			box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
		}

		&.btn-label {
			&,
			&:hover,
			&.focus,
			&:focus,
			&:active,
			&:active:hover,
			&:active:focus {
				cursor: default;
				background-color: #fff;
				border-color: #ccc;
				box-shadow: none;
			}
		}
	}

	/* Loader color */
	.btn-default.btn-loading::before {
		border-color: var(--gray-light);
		border-top-color: var(--primary-color);
	}

	.btn-primary {
		color: #fff;
		background-color: var(--primary-color);
		border-color: var(--primary-color);

		&:hover,
		&:focus,
		&:active,
		&:active:hover,
		&:active:focus {
			color: #fff;
			background-color: #24303d;
			border-color: #24303d;
		}
	}

	.btn-success {
		color: #fff;
		background-color: var(--success-color);
		border-color: var(--success-color);

		&:hover,
		&:focus,
		&:active,
		&:active:hover,
		&:active:focus {
			color: #fff;
			background-color: #7e9800;
			border-color: #7e9800;
		}
	}

	.btn-info {
		color: #fff;
		background-color: var(--info-color);
		border-color: var(--info-color);

		&:hover,
		&:focus,
		&:active,
		&:active:hover,
		&:active:focus {
			color: #fff;
			background-color: #4182dc;
			border-color: #4182dc;
		}
	}

	.btn-warning {
		color: #fff;
		background-color: var(--warning-color);
		border-color: var(--warning-color);

		&:hover,
		&:focus,
		&:active,
		&:active:hover,
		&:active:focus {
			color: #fff;
			background-color: #e66700;
			border-color: #e66700;
		}
	}

	.btn-danger {
		color: #fff;
		background-color: var(--error-color);
		border-color: var(--error-color);

		&:hover,
		&:focus,
		&:active,
		&:active:hover,
		&:active:focus {
			color: #fff;
			background-color: #c11a26;
			border-color: #c11a26;
		}
	}

	/**
	 * Btn helpers
	 */

	.btn-gray-light {
		color: #fff;
		background-color: var(--gray-light);
		border-color: var(--gray-light);

		&:hover,
		&:focus,
		&:active,
		&:active:hover,
		&:active:focus {
			color: #fff;
			background-color: #6a6a6a;
			border-color: #6a6a6a;
		}
	}

	.btn-group-rg > .btn,
	.btn-rg {
		padding: 7px 13px;
		font-size: 15px;
		line-height: 1.3667;
		border-radius: 4.5px;
	}

	.btn-group-md > .btn,
	.btn-md {
		padding: 8px 14px;
		font-size: 16px;
		line-height: 1.4;
		border-radius: 5px;
	}

	.btn-float {
		border-color: transparent;
		box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);

		&:hover {
			border-color: transparent;
			box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
		}
	}

	.btn-circle {
		height: 38px;
		width: 38px;
		padding: 0;
		vertical-align: middle;
		line-height: 38px;
		border-radius: 50%;
		text-align: center;

		> i {
			margin: 0;
		}

		+ .btn-circle {
			margin-left: 0;
		}

		&.btn-xs {
			height: 22px;
			width: 22px;
			line-height: 22px;
		}
	}

	.btn.disabled,
	.btn[disabled],
	fieldset[disabled] .btn {
		pointer-events: none;
	}
}

/* Custom page editor */
.mce-content-body[contentEditable='true']:focus {
	outline-offset: 8px;
}
