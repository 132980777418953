.gallery {
	clear: both;
	overflow: hidden;
	margin-left: -40px;
	margin-bottom: -40px;

	li {
		float: left;
		width: 25%;
		padding-left: 40px;
		margin-bottom: 40px;
		height: 150px;
		overflow: hidden;

		@media all and (max-width: 767px) {
			width: 33%;
		}

		@media all and (max-width: 425px) {
			width: 50%;
		}
	}
}
