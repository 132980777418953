#event-partners {
	ul {
		clear: both;
		overflow: hidden;
		text-align: center;
		margin-bottom: -20px;

		li {
			width: 24%;
			padding: 0 10px;
			margin-bottom: 20px;
			display: inline-block;
			vertical-align: middle;
			word-wrap: break-word;

			@media all and (max-width: 767px) {
				width: 49%;
			}

			&.unique {
				width: 100%;
				height: auto;
				margin-left: 0;
			}
		}
	}
}
