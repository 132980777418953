.alert-container {
	--gray-light: #777;
	--info-color: #5790e0;
	--success-color: #93b100;
	--warning-color: #ff7200;
	--error-color: #d71d2a;

	position: fixed;
	margin: 0 30px;
	bottom: 30px;
	left: 0;
	max-width: 550px;
	z-index: 1060;

	@media all and (max-width: 767px) {
		width: 100%;
	}

	.alert {
		float: left;
		clear: both;
		margin-top: 10px;
		box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
		overflow-wrap: break-word;
		border-radius: 0;
		line-height: 24px;
		margin-bottom: 10px;

		@media all and (max-width: 767px) {
			margin-top: 10px;
		}

		.close {
			color: #fff;
			opacity: 0.8;
			text-shadow: none;

			&:focus,
			&:hover {
				opacity: 1;
				text-shadow: 0 1px 0 var(--gray-light);
			}

			&:focus:active,
			&:hover:active {
				text-shadow: none;
			}
		}

		&::before {
			display: block;
			float: left;
			font-family: 'FontAwesome';
			content: '';
			font-size: 24px;
			line-height: normal;
		}

		a {
			color: white;
			text-decoration: underline;
		}
	}

	.alert-dismissable,
	.alert-dismissible {
		padding-right: 40px;
	}

	.alert-success {
		color: #fff;
		background-color: var(--success-color);
		border-color: var(--success-color);

		&::before {
			margin-right: 10px;
			content: '\f058';
		}
	}

	.alert-warning {
		color: #fff;
		background-color: var(--warning-color);
		border-color: var(--warning-color);

		&::before {
			margin-right: 10px;
			content: '\f071';
		}
	}

	.alert-danger {
		color: #fff;
		background-color: var(--error-color);
		border-color: var(--error-color);

		&::before {
			margin-right: 10px;
			content: '\f06a';
		}
	}
}

/* Toast animations */

@keyframes slideInLeft {
	from {
		transform: translate3d(-120%, 0, 0);
		visibility: visible;
	}

	to {
		transform: translate3d(0, 0, 0);
	}
}

@keyframes slideOutLeft {
	from {
		transform: translate3d(0, 0, 0);
	}

	to {
		visibility: hidden;
		transform: translate3d(-120%, 0, 0);
	}
}

.toast-enter {
	animation: slideInLeft;
	animation-duration: 250ms;
	animation-fill-mode: forwards;
}

.toast-exit {
	animation: slideOutLeft;
	animation-duration: 250ms;
	animation-fill-mode: forwards;
}
