footer {
	position: absolute;
	bottom: 0;
	width: 100%;

	.social-network {
		clear: both;
		overflow: hidden;
		padding: 30px 0;
		border-top: 1px var(--iy-fog-light) solid;
		border-bottom: 4px solid var(--default-text-color);

		.social-footer {
			float: right;
			width: 60%;

			@media all and (max-width: 767px) {
				float: none;
				width: 100%;
				margin: 0;
				text-align: center;
			}

			ul {
				float: right;

				li {
					float: left;
					width: auto;
					padding: 0;
					margin-left: 10px;

					&:first-child {
						margin-left: 0;
					}
				}

				@media all and (max-width: 767px) {
					float: none;

					li {
						float: none;
						display: inline-block;
					}
				}
			}
		}

		.logo-footer {
			float: left;
			width: 40%;

			& > a {
				vertical-align: middle;
				margin-right: 15px;
			}

			.fo_foot_logo {
				max-height: 40px;
				max-width: 200px;
			}

			ul {
				display: inline-block;
			}

			@media all and (max-width: 767px) {
				float: none;
				width: 100%;
				margin-top: 20px;
				text-align: center;
			}
		}
	}

	.credits {
		clear: both;
		overflow: hidden;
		padding: 30px 0;
		background-color: #fff;

		@media all and (max-width: 767px) {
			padding: 15px 0;
		}

		.img-credits {
			display: inline-block;
			width: 25%;
			vertical-align: middle;

			@media all and (max-width: 767px) {
				width: 100%;
				margin-bottom: 10px;
				text-align: center;
			}
		}

		.text-credits {
			display: inline-block;
			width: 74%;
			text-align: right;
			color: var(--gray);
			font-size: 0.9rem;
			white-space: nowrap;

			@media all and (max-width: 767px) {
				width: 100%;
				margin: 0;
				text-align: center;
			}

			a {
				color: var(--gray-dark);
				font-weight: bold;

				&:hover,
				&:focus {
					color: var(--gray-base);
					text-decoration: underline;
				}
			}
		}
	}
}

.footer-credits {
	padding-bottom: 96px;

	@media all and (max-width: 767px) {
		padding-bottom: 100px;
	}
}

.footer-social-network {
	padding-bottom: 111px;

	@media all and (max-width: 767px) {
		padding-bottom: 171px;
	}
}

.footer-credits.footer-social-network {
	padding-bottom: 207px;

	@media all and (max-width: 767px) {
		padding-bottom: 271px;
	}
}

.scroll-to-top {
	position: fixed;
	right: 15px;
	bottom: 15px;
	z-index: 1000;
	display: none;
	padding: 9px 6px;
	opacity: 0.6;

	> i {
		font-size: 2em;
		font-weight: bold;
	}

	&:hover,
	&:focus {
		opacity: 0.9;
	}
}
