#event-organizer {
	background-color: var(--iy-fog-light);

	.content-organizer {
		width: 100%;
		white-space: nowrap;
		text-align: center;
	}

	.block-organizer {
		display: inline-block;
		width: 33%;
		padding: 0 10px;
		white-space: normal;
		vertical-align: middle;
		text-align: center;

		@media all and (max-width: 767px) {
			display: block;
			width: 100%;
			margin-bottom: 10px;
		}
	}

	.title-organizer {
		text-align: center;
		white-space: normal;
		font-size: 1.5rem;
	}

	.all-elements {
		margin-top: 30px;
	}
}
