/* Remove scroll on the body when react-modal is open */
.ReactModal__Body--open {
	overflow: hidden;
}

.modal-dialog {
	margin: 20px;

	@media (min-width: 768px) {
		margin: 50px auto;
	}
}

.modal-content {
	border: none;
	border-radius: 0;
	box-shadow:
		0 2px 2px 0 rgba(0, 0, 0, 0.14),
		0 1px 5px 0 rgba(0, 0, 0, 0.12),
		0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.modal-body {
	max-height: 70vh;
	overflow: auto;
}

/* Fix out animation */
.fade.out {
	opacity: 0;
}

.modal.out .modal-dialog {
	transform: translateY(-25%);
}

/** Flash modal component **/

.flashModal-component {
	background: rgba(0, 0, 0, 0.5);

	.modal-header {
		position: relative;
		padding: 45px;
		text-align: center;

		.close {
			position: absolute;
			top: 15px;
			right: 15px;
		}
	}

	.modal-body {
		padding: 30px;
		color: var(--gray);

		h4 {
			font-weight: bold;
		}

		p {
			margin-bottom: 15px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.modal-footer {
		border-top: none;
		text-align: center;

		@media all and (max-width: 767px) {
			.btn {
				width: 100%;
				margin-bottom: 15px;
				margin-left: 0;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}

.modal-success {
	.modal-header {
		background-color: var(--success-color);
		color: #fff;
	}
}

.modal-warning {
	.modal-header {
		background-color: var(--warning-color);
		color: #fff;
	}
}
