#event-location {
	position: relative;

	.section-container {
		.address-localisation {
			font-weight: 300;
			margin-bottom: 10px;

			&:last-child {
				margin-bottom: 0;
				white-space: pre-wrap;
			}
		}

		.title-localisation {
			font-size: 1.5rem;
			text-align: center;
		}

		@media all and (min-width: 768px) {
			overflow: auto;
			position: absolute;
			top: 20px;
			left: 20px;
			max-width: 300px;
			width: 100%;
			max-height: 60vh;
			padding: 20px;
			background-color: #fff;
			color: #666;
			border-radius: 2px;
			z-index: 2;

			&::-webkit-scrollbar {
				width: 6px;
				margin-right: 4px;
				cursor: pointer;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 7px;
				background-color: var(--default-text-color);
				cursor: pointer;
			}

			&::-webkit-scrollbar-thumb:hover {
				opacity: 0.8;
			}
		}
	}
}

#gmap {
	height: 50vh;
	border: 10px solid transparent;

	@media all and (min-width: 768px) {
		height: 70vh;
	}
}
