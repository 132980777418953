#event-program {
	.program-sections {
		@media all and (max-width: 767px) {
			display: none;
		}

		.program-section {
			display: inline-block;

			a {
				display: inline-block;
				padding: 15px;
				border-bottom: 2px solid transparent;
				font-weight: bold;
				text-decoration: none;
				color: var(--titlevariant);
			}
		}

		.program-section.active a {
			border-bottom: 2px solid;
		}
	}

	.program-section-title {
		display: none;

		@media all and (max-width: 767px) {
			display: block;
			padding: 15px 0;
			border-bottom: 2px solid;
			font-weight: bold;
			color: var(--titlevariant);
		}
	}

	.program-timeline {
		padding: 30px;
		background-color: rgba(0, 0, 0, 0.05);

		@media all and (max-width: 767px) {
			padding: 10px;
		}
	}

	.section-timeline {
		display: none;

		@media all and (max-width: 767px) {
			display: block;
		}

		&.active {
			display: block;
		}
	}

	.period {
		margin-bottom: 15px;
		display: flex;

		@media all and (max-width: 767px) {
			display: block;
		}

		.period-time,
		.period-info {
			vertical-align: top;
			display: inline-block;
		}

		.period-time {
			width: 20%;
			font-weight: bold;
			padding-top: 10px;
			text-align: center;

			@media all and (max-width: 767px) {
				width: 20%;
				text-align: left;
			}
		}

		.period-info {
			max-width: 79%;
			flex-grow: 1;
			border-left: 1px solid var(--textvariant);
			padding-left: 10px;

			@media all and (max-width: 767px) {
				margin-top: 20px;
				display: block;
				margin-left: 20px;
			}

			ul {
				list-style-type: disc;
				list-style-position: inside;
			}

			ol {
				list-style-type: decimal;
				list-style-position: inside;
			}

			ul ul,
			ol ul {
				list-style-type: circle;
				list-style-position: inside;
				margin-left: 15px;
			}

			ol ol,
			ul ol {
				list-style-type: lower-latin;
				list-style-position: inside;
				margin-left: 15px;
			}

			.period-title {
				font-weight: bold;
				padding: 10px 0;
			}

			.period-content {
				margin-top: 10px;
			}
		}
	}
}
