#participants {
	.form {
		margin-top: 0;
		margin-bottom: 30px;
		padding: 0;
		border: none;
		text-align: center;

		@media all and (max-width: 768px) {
			margin-bottom: 15px;
		}

		.input-group-addon {
			border: none;
			min-width: 50px;
		}

		.icon-helper {
			height: 50px;
			width: 50px;
			line-height: 50px;
			display: inline-block;
			z-index: 10;
			cursor: pointer;
			pointer-events: auto;
			color: #666;

			&:hover {
				color: #333;
			}
		}
	}

	.participants-empty {
		text-align: center;
		margin: 0 80px;

		@media all and (max-width: 768px) {
			margin: 0;
		}
	}
}
