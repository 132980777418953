h1 {
	font-size: 3rem;
}

h2 {
	font-size: 2.5rem;
}

h3 {
	font-size: 2rem;
}

h4 {
	font-size: 1.5rem;
}

h5 {
	font-size: 1.25rem;
}

h6 {
	font-size: 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-bottom: 1.5rem;
	font-weight: 600;
	line-height: 1.125;
}
