.badge-success,
.badge-completed {
	background-color: var(--success-color);
}

.badge-info,
.badge-awaiting_payment {
	background-color: var(--info-color);
}

.badge-warning,
.badge-refunded {
	background-color: var(--warning-color);
}

.badge-error,
.badge-cancelled {
	background-color: var(--error-color);
}
