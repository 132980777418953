* {
	box-sizing: border-box;
}

html {
	height: 100%;
	font-size: var(--default-size-font);
}

body {
	position: relative;
	min-height: 100%;
	font-family: var(--default-family-font);
	background-color: var(--bgvariant);
	color: var(--textvariant);
	line-height: 1.5;
}

a {
	color: var(--linkvariant);
	transition: all 0.2s ease-in;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}

	&:hover,
	&:focus,
	&:active {
		color: var(--linkvariant);
	}
}

img {
	max-width: 100%;
	height: auto;
}

input[type='submit'] {
	transition: all 0.2s ease-in;
	cursor: pointer;
}

input,
textarea,
select {
	font-size: 1rem;
	font-family: var(--default-family-font);
	color: var(--default-text-color);
}

strong {
	font-weight: bold;
}

em {
	font-style: italic;
}

p {
	margin-bottom: 0.75rem;
}

small {
	font-size: smaller;
}

sup {
	vertical-align: super;
	font-size: smaller;
}

section#start,
#start-login {
	scroll-margin-top: 180px;
}

.section-long {
	min-height: 800px;
}
