/* Spinner */
.spinner {
	--spinner-size: 50px;
	--spinner-position-after: -5px;

	width: var(--spinner-size);
	height: var(--spinner-size);
	display: inline-block;
	position: relative;
	vertical-align: middle;
	border-radius: var(--spinner-size);
	border: 5px solid var(--textvariant);
}

.spinner::after {
	--spinner-size: 50px;
	--spinner-position-after: -5px;

	content: '';
	position: absolute;
	top: var(--spinner-position-after);
	left: var(--spinner-position-after);
	bottom: var(--spinner-position-after);
	right: var(--spinner-position-after);
	border-radius: var(--spinner-size);
	border: 5px solid transparent;
	border-top-color: var(--default-text-color);
	animation: spin 1.2s linear infinite;
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}
