/* Bootstrap */

.form-control {
	padding: 8px 10px;
	border: 1px solid var(--border-color);
	border-radius: 5px;
	font-weight: 300;
	height: 50px;
	font-size: 1rem;
	overflow: hidden;

	&::placeholder {
		color: var(--gray-light);
	}
}

textarea.form-control {
	resize: vertical;
	min-height: 150px;
}

select.form-control {
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
	cursor: pointer;
}

.form-group > label {
	margin-bottom: 5px;
}

/* Form */

.form {
	--label-width: 40%;
	--input-width: 60%;

	position: relative;
	margin-top: 20px;
	border: 1px solid var(--textvariant);
	border-radius: 5px;
	padding: 55px 30px 30px;
	font-weight: 300;
	font-family: var(--default-family-font);

	@media all and (max-width: 767px) {
		border: none;
		padding: 0;
		margin: 0 50px;
	}

	@media all and (max-width: 426px) {
		margin: 0;
	}

	/* Form title */
	.form-title {
		font-size: 1.3rem;
		font-weight: 600;
		position: absolute;
		top: -19px;
		margin-left: 30px;
		margin-bottom: 0;
		padding: 8px 15px;
		line-height: 1;
		background-color: var(--bgvariant);

		@media all and (max-width: 767px) {
			position: relative;
			margin: 0;
			padding: 0 0 30px;
			top: 0;
		}

		.breadcrumb {
			background-color: transparent;
			margin-bottom: 0;
			padding: 0;

			> li {
				text-transform: capitalize;
			}
		}
	}

	/* Section title (magicform) */
	.form-content-title {
		font-weight: bold;
		padding-bottom: 10px;
		border-bottom: 1px solid var(--border-color);
		margin-top: 10px;
		margin-bottom: 20px;
		font-size: 1.1rem;

		@media all and (max-width: 767px) {
			margin-top: 0;
			margin-bottom: 10px;
		}
	}

	/* Inputs */
	p.legend {
		display: block;
		float: right;
		width: var(--input-width);
		margin-top: 10px;
		margin-bottom: 0;
		text-align: left;
		font-size: 14px;

		@media all and (max-width: 767px) {
			float: none;
			width: 100%;
		}
	}

	.legend {
		margin-top: 20px;
		text-align: left;

		&::after {
			content: '';
			display: table;
			clear: both;
		}
	}

	.form-group {
		margin-right: -15px;
		margin-left: -15px;

		&::before,
		&::after {
			display: table;
			content: ' ';
		}

		&::after {
			clear: both;
		}

		&-row {
			margin-right: 0;
			margin-left: 0;
		}
	}

	.form-col {
		position: relative;
		min-height: 1px;
		padding-right: 15px;
		padding-left: 15px;

		@media (min-width: 768px) {
			float: left;
		}

		@media (min-width: 768px) {
			width: var(--input-width);
		}

		> input[type='checkbox'],
		> input[type='radio'] {
			margin-top: 15px;
			margin-right: 10px;
			float: left;
		}

		> label {
			margin-top: 10px;
			width: 95%;
			display: block;
			float: left;
		}

		&-right {
			float: right;
		}
	}

	.form-group > legend {
		display: inline-block;
		width: auto;
		max-width: 100%;
		color: inherit;
	}

	.control-label,
	.form-group > label,
	.form-group > legend {
		position: relative;
		min-height: 1px;
		padding-right: 15px;
		padding-left: 15px;

		@media (min-width: 768px) {
			float: left;
		}

		padding-top: 10px;

		@media (min-width: 768px) {
			width: var(--label-width);
			text-align: right;
		}
	}

	.radio-group,
	.checkbox-group {
		border: 1px solid transparent;
		border-radius: 5px;
		padding: 0 5px;
	}

	.required .form-control::after,
	.required > label::after,
	.required > legend::after {
		color: var(--error-color);
		content: ' *';
	}

	.form-error .form-control,
	.form-error .radio-group,
	.form-error .checkbox-group {
		border-color: var(--error-color);
	}

	.datepicker .form-control,
	.timepicker .form-control {
		background-color: #fff;
	}

	.filepicker .form-control {
		padding: 0;
		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);

		input {
			display: none;
		}

		label {
			position: relative;
			padding: 8px 10px 8px 60px;
			cursor: pointer;
			width: 100%;
			line-height: 32px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;

			&::before {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				padding: 14px 17px;
				font-family: 'FontAwesome';
				content: '\f093';
				font-size: 16px;
				background-color: var(--border-color);
				line-height: 20px;
			}
		}
	}

	.checkbox label,
	.radio label {
		font-weight: 300;
	}

	.checkbox > input[type='checkbox'] {
		margin-left: 0;
	}

	.form-action {
		text-align: right;

		@media all and (max-width: 767px) {
			.btn {
				width: 100%;
				margin-bottom: 20px;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}

/* Form inline */

.form-inline {
	.btn {
		vertical-align: middle;
	}

	.form-group {
		margin: 0 0 20px;

		@media all and (min-width: 767px) {
			label {
				max-width: 150px;
				margin-right: 5px;
			}

			input {
				width: 300px;
				margin-right: 5px;
			}

			.error-message {
				margin-left: 0;
				text-align: right;
				float: none;
				width: auto;
			}
		}
	}
}

.timepicker-menu {
	left: 15px;
	top: 50px;
	margin: 0;

	.timepicker-form {
		table {
			> tbody {
				> tr {
					> td {
						padding: 5px;
						vertical-align: middle;
					}
				}
			}
		}

		input {
			width: 95px;
			height: 44px;
		}
	}
}

.form-group {
	&.required > label:first-child::after,
	&.required > .control-label:first-child::after {
		color: var(--error-color);
		content: '*';
		margin-left: 2px;
	}
}

.input-group-addon {
	border: 0;
}

.form-group.has-error {
	label {
		color: var(--error-color);
	}

	input,
	select,
	textarea {
		color: var(--error-color);
		border-color: var(--error-color);
	}

	.input-group-addon {
		color: var(--error-color);
		border: 1px solid var(--error-color);
		border-right: 0;
	}

	.help-block {
		color: var(--error-color);
	}

	.error-message {
		color: var(--error-color);
		padding: 5px 0;
	}
}
