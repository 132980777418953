/**
 * Admin navbar
 */
.admin-navbar {
	position: sticky;
	top: 0;
	height: 32px;
	padding: 3px 0;
	background-color: #000;
	z-index: 1001;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
	text-align: left;
	font-size: 10px;

	@media (max-width: 767px) {
		text-align: left;
	}

	.btn {
		color: #fff;
		border: none;
		border-radius: 0;
		text-transform: none;
		font-weight: normal;
		font-size: 13px;

		&::after {
			border-radius: 0;
		}
	}

	.dropdown-menu {
		margin: 0;
		border: solid 1px #ccc;
		border-radius: 0;
		padding: 0;

		@media (max-width: 767px) {
			max-width: 320px;
		}

		> li > a {
			padding: 13px 20px;
		}

		.dropdown-header {
			padding-top: 13px;
			white-space: initial;
			word-break: break-word;
		}
	}
}

.admin-navbar-separator {
	margin: 6px 2px;
	border-left: 1px solid #fff;
	height: 13px;
}

.admin-navbar-custom {
	.tools-custom {
		width: 250px;
		color: var(--gray-darker);

		.content-custom {
			padding: 20px;
		}

		.title-custom {
			margin: 20px 0 10px;
			padding-top: 20px;
			border-top: 1px solid #ccc;

			&:first-child {
				margin-top: 0;
				padding-top: 0;
				border: none;
			}
		}

		ul li {
			line-height: 20px;
			margin-bottom: 10px;
			position: relative;

			.color-pick {
				display: block;
				float: left;
				width: 20px;
				height: 20px;
				margin-right: 15px;
				background-color: #fff;
				cursor: pointer;
				border: 1px solid #ccc;
				text-indent: -9999px;
				outline: none;
			}

			&:last-child {
				margin-bottom: 0;
			}

			.colpick {
				top: 0 !important;
				left: auto !important;
				right: 100% !important;
				z-index: 2000;
				margin-top: -7px;
				margin-right: 7px;
			}

			select {
				max-width: 100%;
				width: 100%;
				margin-top: 5px;
			}
		}
	}

	.save-custom-btn {
		display: block;
		padding: 12px 20px;
		color: var(--gray-darker);
		background-color: var(--gray-lighter);
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;

		&:hover,
		&:focus {
			background-color: #bbb;
			text-decoration: none;
		}

		&-success,
		&-error,
		&.btn-loading {
			cursor: not-allowed;
			pointer-events: none;
		}

		&-success,
		&-error {
			position: relative;
			color: #fff;

			&::before {
				font: normal normal normal 16px/1 FontAwesome;
				position: absolute;
				top: 15px;
				left: 12px;
				display: inline-block;
			}
		}

		&-success,
		&-success:hover,
		&-success:focus {
			background-color: var(--success-color);

			&::before {
				content: '\f058';
			}
		}

		&-error,
		&-error:hover,
		&-error:focus {
			background-color: var(--error-color);

			&::before {
				content: '\f06a';
			}
		}
	}
}
