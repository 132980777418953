.carousel {
	&.fade {
		opacity: 1;

		.carousel-inner {
			.item {
				transition-property: opacity;
				transition-duration: 0.5s;
			}

			.item,
			.active.left,
			.active.right {
				opacity: 0;
			}

			.active,
			.next.left,
			.prev.right {
				opacity: 1;
			}

			.next,
			.prev,
			.active.left,
			.active.right {
				left: 0;
				transform: translate3d(0, 0, 0);
			}
		}
	}
}

.carousel-control {
	z-index: 15;
	width: 50px;

	> i {
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		display: inline-block;
		text-align: center;
		margin-top: -30px;
	}
}
