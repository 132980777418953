.alert {
	margin-top: 0;
	border: none;
	border-left: 10px solid var(--gray-darker);
	background-color: #fff;
	color: var(--gray-dark);
	width: 100%;
	box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.1);
	text-align: left;

	.alert-link:not(.btn) {
		color: var(--gray-dark);
		text-decoration: underline;
	}

	&-danger {
		border-left-color: var(--error-color);
		color: var(--error-color);
	}

	&-success {
		border-left-color: var(--success-color);
		color: var(--success-color);
	}

	&-warning {
		border-left-color: var(--warning-color);
		color: var(--warning-color);
	}
}
