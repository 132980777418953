#event-documents {
	ul {
		clear: both;
		overflow: hidden;

		li {
			padding-left: 25px;
			padding-right: 25px;
			line-height: 50px;
			text-align: center;
			vertical-align: middle;

			@media all and (max-width: 767px) {
				width: 100%;
			}

			a {
				display: inline-block;
				color: var(--textvariant);

				.icon {
					display: inline-block;
					vertical-align: middle;
					margin-right: 15px;
					font-size: 40px;
				}

				.text-document {
					display: inline-block;
					vertical-align: middle;
					text-align: left;

					@media all and (max-width: 767px) {
						width: 100%;
						text-align: center;
					}
				}
			}
		}
	}
}

#event-documents-single,
#sharedfiles {
	ul {
		clear: both;
		overflow: hidden;
		text-align: left;

		li {
			padding: 20px 0;
			border-bottom: 1px solid rgba(0, 0, 0, 0.2);

			&:first-child {
				border-top: 1px solid rgba(0, 0, 0, 0.2);
			}

			.description-documents {
				margin-top: 20px;

				p {
					margin: 0;
				}
			}
		}
	}
}
