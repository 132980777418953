:root {
	/* Default palette colors */
	--iy-primary: #ff8e32;
	--iy-fog-light: rgba(0, 0, 0, 0.03);

	/* Colors variables */
	--background-color: #f7f7f7;
	--link-color: #dc2961; /* Pink */
	--title-color: #6c0362; /* Purple */
	--default-text-color: #5f5f66; /* Grey */
	--border-color: #efefef; /* Grey light */
	--info-color: #4d90fe; /* Green */
	--success-color: #8c0; /* Green */
	--warning-color: #ff7200; /* Orange */
	--error-color: #f00; /* Red */

	/* Fonts variables */
	--default-family-font: 'Source Sans Pro', sans-serif;
	--default-size-font: 16px;
	--strong-weight-font: 800;
	--title-weight-font: 600;

	/* Bootstrap vars */
	--gray-base: #000;
	--gray-darker: #222;
	--gray-dark: #333;
	--gray: #555;
	--gray-light: #777;
	--gray-lighter: #eee;

	/* Event site customizable colors */
	--bgvariant: #ededed;
	--textvariant: #5f5f66;
	--titlevariant: #5790e0;
	--linkvariant: #ff7200;
	--btnbgvariant: #ff7200;
	--btntextvariant: #fff;
	--opacity-banner: 0;
	--color-banner: #252324;

	/**
	 * Media queries can't use css variables, so here are the values
	 * $break-tablets: 1023px;
	 * $break-phones: 767px;
	 */
}
