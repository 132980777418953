.btn {
	padding: 16px 30px;
	background-color: transparent;
	border: 1px solid transparent;
	text-transform: uppercase;
	font-weight: 600;
	font-family: var(--default-family-font);
	transition:
		background-color 0.25s,
		color 0.25s,
		border 0.25s;
	line-height: 1;
	max-width: 100%;
	word-wrap: break-word;
	white-space: normal;
	vertical-align: baseline;
	position: relative;

	&::after {
		content: ' ';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(255, 255, 255, 0);
		border-radius: 4px;
	}

	&:hover,
	&:focus,
	&.focus,
	&:active,
	&:active:hover,
	&:active:focus,
	&:active.focus,
	&.active,
	&.active:hover,
	&.active:focus,
	&.active.focus {
		&::after {
			background-color: rgba(0, 0, 0, 0.05);
		}
	}

	&:hover {
		box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.2);
	}

	&:active,
	&.active {
		box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	}

	+ .btn {
		margin-left: 10px;
	}
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn,
.btn-label {
	&:hover,
	&:focus,
	&.focus,
	&:active,
	&:active:hover,
	&:active:focus,
	&:active.focus,
	&.active,
	&.active:hover,
	&.active:focus,
	&.active.focus {
		box-shadow: none;

		&::after {
			background-color: rgba(0, 0, 0, 0);
		}
	}
}

.btn-label {
	cursor: default;
}

.btn-default {
	border-color: var(--gray);
	background-color: #fff;
	color: var(--gray);
}

.btn-inverse {
	border-color: var(--gray);
	background-color: var(--gray);
	color: #fff;

	&.focus,
	&:focus,
	&:hover {
		color: #fff;
	}
}

.btn-circle {
	padding: 10px;
	border-radius: 20px;
	width: 40px;
	height: 40px;
	line-height: 18px;
	vertical-align: middle;

	&::after {
		border-radius: 20px;
	}
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.btn-loading::before {
	content: '';
	display: inline-flex;
	border-radius: 20px;
	width: 1em;
	height: 1em;
	margin-right: 10px;
	border: 2px solid;
	border-top-color: var(--btnbgvariant);
	animation: spin 1s linear infinite;
}

.btn-lg {
	padding: 15px 65px;
	font-weight: bold;

	&::after {
		border-radius: 6px;
	}

	&.btn-loading::before {
		top: 15px;
		left: 20px;
	}
}

.btn-sm {
	padding: 6px 12px;
}

.btn-info {
	color: #fff;
	background-color: #5bc0de;
	border-color: #46b8da;
}

.btn-success {
	color: #fff;
	background-color: #5cb85c;
	border-color: #4cae4c;
}

.btn-text {
	display: none;

	@media (min-width: 1200px) {
		display: inline;
	}
}

.btn-primary {
	border-color: var(--btnbgvariant);
	background-color: var(--btnbgvariant);
	color: var(--btntextvariant);

	&:hover,
	&:focus,
	&:active {
		border-color: var(--btnbgvariant);
		background-color: var(--btnbgvariant);
		color: var(--btntextvariant);
	}
}

.btn-secondary {
	color: var(--linkvariant);
	border-color: var(--linkvariant);

	&:hover,
	&:focus,
	&:active {
		color: var(--linkvariant);
		border-color: var(--linkvariant);
	}
}

.btn-header {
	color: var(--textvariant);

	&:hover,
	&:focus,
	&:active {
		color: var(--textvariant);
	}
}
