.masthead {
	position: relative;
}

.masthead-carousel-inner {
	width: 100%;
	height: 100%;
	z-index: 5;

	.carousel-item {
		width: 100%;
		height: 100%;
		min-height: 250px; /** Minimum required space for title, subtitle and button  **/

		> img {
			display: block;
			width: 100%;
			height: 100%;
			min-height: 250px;
			object-fit: cover;
		}
	}
}

.masthead-overlay {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
	background-color: var(--color-banner);
	opacity: var(--opacity-banner);
}

.masthead-container {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	min-height: 100%;
	height: 10px; /* Fix for flexbox on IE */
	z-index: 15;
	display: flex;
	align-items: center;
	padding: 25px 50px;
}

/* Prevent text from colliding with nav when nav is transparent */
body:has(.navigation-transparent) .masthead-container {
	padding-top: 100px;
}

.masthead-content {
	width: 100%;
	text-align: center;
	color: #fff;
	line-height: 1;
}

.masthead-heading {
	font-size: 1.5rem;
	text-transform: uppercase;
	margin-top: 20px;
	margin-bottom: 20px;

	@media all and (min-width: 425px) {
		font-size: 3rem;
	}
}

.masthead-lead {
	margin-top: 20px;
	margin-bottom: 20px;
	font-size: 1.1rem;

	@media all and (min-width: 425px) {
		font-size: 1.5rem;
	}
}

.masthead-btn {
	margin-top: 20px;
	margin-bottom: 20px;
}

.mobile-button {
	display: none;
	text-align: center;
	padding: 25px 0;
}

/* Banner styles */
.header-image-contain {
	.masthead-carousel-inner .carousel-item > img {
		object-fit: contain;
		max-height: 75vh;
	}

	/* Only enabled on mobile */
	@media all and (max-width: 425px) {
		.masthead-container .masthead-content #event-rsvp,
		.masthead-container .masthead-content #event-survey {
			display: none;
		}

		.mobile-button {
			display: block;
		}
	}
}

.header-image-cover {
	.masthead-carousel-inner .carousel-item > img {
		height: 540px;
		object-fit: cover;
	}
}

.header-image-fullscreen {
	.masthead {
		height: 90vh;
	}

	.masthead-carousel-inner .carousel-item > img {
		max-height: none;
		object-fit: cover;
	}
}

/* When navbar is transparent header needs to take full height */
body:has(.navigation-transparent) {
	.header-image-fullscreen .masthead {
		height: 100vh;
	}

	&:has(#event-admin-navbar) {
		.header-image-fullscreen .masthead {
			height: calc(100vh - 32px);
		}
	}
}

/* Banner text horizontal alignement */
.header-text-horizontal-left {
	.masthead-content {
		text-align: left;
	}
}

.header-text-horizontal-center {
	.masthead-content {
		text-align: center;
	}
}

.header-text-horizontal-right {
	.masthead-content {
		text-align: right;
	}
}

/* Banner text vertical alignement */
.header-text-vertical-top {
	.masthead-container {
		align-items: flex-start;
	}
}

.header-text-vertical-middle {
	.masthead-container {
		align-items: center;
	}
}

.header-text-vertical-bottom {
	.masthead-container {
		align-items: flex-end;
	}
}
