#event-speakers {
	@media all and (max-width: 767px) {
		.all-elements {
			margin-top: 0;
		}
	}

	.content-speakers {
		width: 100%;
		text-align: center;

		@media all and (max-width: 425px) {
			text-align: left;
		}

		.element-speakers {
			display: inline-block;
			width: 32%;
			padding: 15px;
			vertical-align: top;
			text-align: center;
			height: 100%;
			word-wrap: break-word;

			@media all and (max-width: 425px) {
				width: 49%;
			}

			img {
				display: block;
				margin: 0 auto 25px;
				border-radius: 100%;
				height: 140px;
				width: 140px;
				object-fit: cover;

				@media all and (max-width: 767px) {
					height: 100px;
					width: 100px;
				}
			}

			.name {
				color: var(--titlevariant);
				text-transform: uppercase;
			}

			.job {
				font-weight: 300;
			}

			ul {
				margin: 0 auto;
				text-align: center;

				li {
					display: inline-block;
					text-align: center;
					margin: 10px 10px 0;
					width: auto;
					padding: 0;

					&:first-child {
						margin: 10px 5px 0;
					}
				}
			}

			.speakers-description {
				white-space: pre-wrap;
			}
		}
	}
}
