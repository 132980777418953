.ticketing {
	color: #5f5f66;

	a:hover {
		cursor: pointer;
	}

	form {
		.required {
			color: var(--error-color);
		}
	}

	.nav > li {
		> a {
			color: #5f5f66;
		}

		&.disabled > a {
			color: #9e9ea9;
		}
	}
}

.category .category {
	margin-left: 25px;
}

.not-valid {
	color: var(--error-color);
	margin-bottom: 5px;
}

.ticket {
	position: relative;
	margin-bottom: 20px;
	box-shadow:
		0 1px 3px rgba(0, 0, 0, 0.12),
		0 1px 2px rgba(0, 0, 0, 0.24);
	background-color: white;
	border-left: 20px solid #555;
	padding: 15px;
	max-width: 550px;

	.badge {
		margin-top: 0;
		margin-left: 3px;
	}

	&::after {
		position: absolute;
		opacity: 0;
		content: attr(data-edit);
		background-color: var(--default-text-color);
		font-weight: bold;
		font-size: 18px;
		left: 40%;
		bottom: 0;
		color: white;
		z-index: 1;
		padding: 5px 10px;
		text-align: center;
		border: 1px solid var(--default-text-color);
		transition: all 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
	}

	&.ticket-active::after {
		display: none;
	}

	&:hover::after {
		opacity: 1;
		transform: translateY(-50%);
	}

	&::before {
		content: '\A';
		position: absolute;
		padding: 12px;
		border-radius: 50%;
		top: 50%;
		left: -25px;
		transform: translate(-45%, -50%);
		background-color: #fff;
	}

	&:hover .plus-icon-ticket {
		font-size: 24px;
	}

	&.ticket-active .filling-ticket-active {
		max-width: 100%;
	}

	.filling-ticket-active {
		content: '';
		position: absolute;
		left: 0;
		width: 100%;
		max-width: 1px;
		top: 0;
		height: 100%;
		transition: 0.2s ease;
	}

	span {
		z-index: 10;
	}

	.heading {
		display: flex;

		.name {
			flex-grow: 1;
		}

		.price {
			padding-left: 5px;
			flex-shrink: 0;
		}
	}

	.caption {
		margin-top: 30px;
		white-space: pre-line;
		font-style: italic;
	}

	.description {
		margin-top: 10px;
		font-size: 14px;
		margin-bottom: 22px;
	}

	&.ticket-active .caption {
		color: white;
	}

	&.ticket-active {
		color: white;
	}
}

.order-summary {
	box-shadow:
		0 1px 3px rgb(0 0 0 / 12%),
		0 1px 2px rgb(0 0 0 / 24%);
	background-color: white;

	.tickets {
		padding-bottom: 10px;

		li {
			margin-bottom: 10px;
		}

		.subtotal {
			margin-top: 5px;
			text-align: right;

			.amount {
				display: inline-block;
				min-width: 70px;
				border-top: 1px solid var(--iy-fog-light);
				padding: 2px;
			}
		}

		.coupon-code {
			margin-left: 10px;
		}
	}

	.total {
		padding: 10px 0;
		border-top: 1px solid var(--iy-fog-light);

		.amount {
			float: right;
		}
	}
}

.coupons-form {
	margin-top: 30px;

	ul {
		margin-bottom: 10px;
	}

	li {
		display: inline-block;
		border: 1px solid var(--iy-fog-light);
		border-radius: 4px;
		padding: 10px 14px;
		font-size: 14px;
		line-height: 1;
		margin-right: 10px;
		margin-bottom: 10px;

		i {
			margin-left: 5px;
		}
	}

	.form-group input {
		width: 200px;
		height: 36px;
		border: solid 1px rgba(0, 0, 0, 0.3);
	}

	.btn {
		padding: 10px 14px;
		font-size: 12px;
		line-height: 14px;
	}
}

.payment-methods {
	display: flex;
	justify-content: center;
	margin-bottom: 30px;
}

.payment-method {
	border: 1px solid var(--iy-fog-light);
	border-radius: 4px;
	margin-left: 10px;
	margin-right: 10px;
	padding: 30px;
	user-select: none;
	transition: background-color 0.2s ease-out;

	&:hover {
		cursor: pointer;
	}

	&:hover:not(.selected) {
		background-color: var(--iy-fog-light);
	}

	i {
		font-size: 42px;
		margin-right: 15px;
		vertical-align: middle;
	}
}

.ticketing_user {
	padding: 0 35px;
	margin: 10px 0;

	&:not(:last-child) {
		border-bottom: solid 1px #ddd;
	}

	span {
		margin-top: 10px;
	}

	svg {
		width: 40px;
		height: 40px;
		transition: 0.2s ease;
	}

	&:hover .plus-icon-user {
		font-size: 36px;
	}

	&:hover svg {
		width: 50px;
		height: 50px;
	}

	&.ticketing_user_active svg {
		width: 70px;
		height: 70px;
	}

	&.ticketing_user_active span {
		font-weight: bold;
	}

	&.ticketing_user_active .ticket.ticket-inactive {
		visibility: visible;
		opacity: 1;
		height: auto;
		padding: 15px;
		margin-bottom: 20px;
	}

	& .ticket.ticket-inactive {
		visibility: hidden;
		opacity: 0;
		height: 0;
		margin: 0;
		padding: 0;
	}

	& .category > div:first-child {
		visibility: hidden;
		opacity: 0;
		height: 0;
		transition: 0.2s ease;
	}

	&.ticketing_user_active .category > div:first-child {
		visibility: visible;
		opacity: 1;
		height: auto;
	}
}

.ticket-inactive {
	transition: 0.2s ease;
}

.white-bar-left {
	position: absolute;
	height: 100%;
	display: block;
	content: '';
	width: 1px;
	border-left: white 3px solid;
	left: 1px;
	top: 0;
	z-index: 30;
}

.btn-ticketing-1 {
	color: rgba(18, 115, 222, 1);
	border-color: rgba(18, 115, 222, 1);

	&:hover {
		color: rgba(18, 115, 222, 1);
	}
}

.btn-ticketing-2 {
	background-color: rgba(18, 115, 222, 1);
	border-color: rgba(18, 115, 222, 1);
	color: white;
}

.text-ticketing {
	color: rgba(18, 115, 222, 1);
}

.plus-icon-ticket {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	font-size: 18px;
	transition: 0.2s ease;
}

.plus-icon-user {
	display: block;
	margin-right: auto;
	margin-left: auto;
	font-size: 30px;
	transition: 0.2s ease;
}
