.main-navbar {
	margin-bottom: 0;
	border: none;
	border-radius: 0;
	background-color: var(--bgvariant);
	border-top: 4px solid var(--titlevariant);

	.main-navbar-container {
		@media (min-width: 768px) {
			padding-top: 15px;
			padding-bottom: 15px;
		}
	}

	.main-navbar-header {
		float: left;
	}

	.main-navbar-brand {
		padding: 14px 15px;
		height: 70px;
		line-height: 42px;

		@media (min-width: 768px) {
			padding: 0 15px;
			height: 70px;
			line-height: 70px;
		}

		> img {
			display: initial;
			max-width: 120px;
			max-height: 42px;
			width: auto;
			height: auto;
			vertical-align: middle;

			@media (min-width: 768px) {
				max-width: 180px;
			}

			@media (min-width: 1200px) {
				max-height: 70px;
				max-width: 100%;
			}
		}
	}

	.main-navbar-toggle {
		padding: 0;
		border: none;
	}

	.main-navbar-nav {
		margin: 14px 0 14px 10px;
		float: right;

		> li > a {
			text-transform: uppercase;
			padding: 12px;
			line-height: 18px;
			border-radius: 4px;

			&:focus,
			&:hover {
				background-color: rgba(0, 0, 0, 0.05);
			}
		}
	}

	.main-navbar-btn {
		margin: 14px 0 14px 10px;
		float: right;

		&.btn,
		> .btn {
			padding: 12px;
			line-height: 16px;
			max-width: 200px;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow-x: hidden;

			@media all and (max-width: 405px) {
				max-width: 120px;
			}
		}
	}

	.main-navbar-collapse {
		padding: 0;

		.main-navbar-close {
			display: none;
		}

		@media (max-width: 767px) {
			float: none;
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: 0;
			z-index: 1010;
			background-color: rgba(0, 0, 0, 0.4);
			border: none;
			overflow: hidden;
			opacity: 0;
			visibility: hidden;

			.main-navbar-nav {
				position: absolute;
				z-index: 1020;
				top: 0;
				right: -450px;
				height: 100%;
				width: 450px;
				max-width: 90%;
				margin: 0;
				padding: 20px 80px 20px 30px;
				overflow-y: auto;
				transition: all 0.4s ease 0s;
				background-color: var(--bgvariant);

				> li {
					float: none;

					> a {
						display: block;
						line-height: 30px;

						&:focus,
						&:hover {
							background-color: transparent;
						}
					}
				}
			}

			&.open {
				visibility: visible;
				opacity: 1;

				.main-navbar-nav {
					right: 0;
				}
			}

			.main-navbar-close {
				display: block;
				position: absolute;
				z-index: 1030;
				top: 4px;
				right: 0;
				margin: 14px;
			}
		}
	}

	.dropdown-menu {
		right: 0;
		left: auto;
		max-width: 200px;

		> .dropdown-header {
			white-space: initial;
			word-break: break-word;
		}

		> li > a {
			padding: 9px 20px;
			white-space: initial;
			word-break: break-word;
		}
	}
}

/* Fixed */
.navigation-fixed {
	.main-navbar,
	&.main-navbar {
		position: sticky;
		top: 0;
		z-index: 1000;
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
	}
}

/* Alignement */
.navigation-logo-right {
	.main-navbar-header {
		float: right;
	}

	.main-navbar-btn,
	.main-navbar-nav {
		float: left;
	}

	.main-navbar-collapse {
		@media (max-width: 767px) {
			.main-navbar-nav {
				left: -450px;
				right: auto;
				padding: 20px 30px 20px 80px;
			}

			&.open {
				.main-navbar-nav {
					left: 0;
					right: auto;
				}
			}

			.main-navbar-close {
				left: 0;
				right: auto;
			}
		}
	}

	.dropdown-menu {
		right: auto;
		left: 0;
	}
}

/* Transparent background */
.navigation-transparent {
	.main-navbar,
	&.main-navbar {
		background-color: transparent;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		z-index: 20;
		box-shadow: none;
	}

	&.navigation-fixed {
		padding-top: 0;

		.main-navbar,
		&.main-navbar {
			position: fixed;
		}
	}

	@media (min-width: 768px) {
		.main-navbar .main-navbar-nav,
		&.main-navbar .main-navbar-nav {
			background-color: transparent;
		}
	}
}

/** When nav is floaty, we need to mind the admin bar **/
body:has(#event-admin-navbar) #event-navigation.navigation-transparent,
body:has(#event-admin-navbar) #event-navigation.navigation-fixed {
	top: 32px;

	.main-navbar-collapse.open {
		top: 32px;
	}
}

/* Disable transparent nav when no header */
body:not(:has(#event-header)) #event-navigation {
	position: initial;
	top: initial;
}
