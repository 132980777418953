#details {
	background-color: var(--iy-fog-light);

	ul {
		display: flex;
		justify-content: center;

		@media all and (max-width: 767px) {
			flex-direction: column;
		}

		li {
			display: flex;
			align-items: center;
			flex-basis: 100%;
			max-width: 525px;
			padding: 0 25px;
			font-weight: 300;

			&:first-child {
				justify-content: flex-end;
			}

			&:last-child {
				justify-content: flex-start;
			}

			&:only-child {
				justify-content: center;
			}

			@media all and (max-width: 767px) {
				max-width: initial;
				justify-content: flex-start !important;
				margin-bottom: 30px;
				padding: 0 25px 30px;
				border-bottom: 1px solid rgba(0, 0, 0, 0.2);

				&:last-child {
					padding-bottom: 0;
					margin-bottom: 0;
					border-bottom: none;
				}
			}

			.icon {
				font-size: 40px;
				margin-right: 20px;
			}
		}
	}

	.add-calendar {
		margin-top: 30px;

		@media all and (max-width: 767px) {
			padding-top: 30px;
			border-top: 1px solid rgba(0, 0, 0, 0.2);
		}
	}
}
