.list-card {
	margin-left: -15px;
	margin-right: -15px;

	> li {
		padding: 0 15px 30px;
		width: 50%;

		@media all and (max-width: 767px) {
			width: 100%;
		}
	}
}

.card {
	display: inline-block;
	vertical-align: top;
	position: relative;
	border: 1px solid var(--border-color);
	background-color: white;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
	color: var(--default-text-color);
	width: 100%;

	&-content {
		padding: 15px;
	}

	&-title {
		font-weight: 500;
	}
}

.card-recommendation,
.card-companions {
	background-color: var(--bgvariant);

	.card-content {
		min-height: 180px;
	}

	.card__mail,
	.card__job {
		margin-top: 10px;
	}

	.card__job {
		font-style: italic;
	}
}
