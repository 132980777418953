#event-description {
	.container {
		padding-top: 80px;

		@media all and (max-width: 767px) {
			padding-top: 50px;
		}
	}

	.content-description {
		font-weight: 300;

		p {
			margin-bottom: 20px;
		}

		strong {
			font-weight: 600;
		}

		u {
			border-bottom: 1px solid var(--default-text-color);
			text-decoration: none;
		}

		i {
			font-style: italic;
		}

		ul {
			clear: both;
			margin-top: 1em;
			margin-bottom: 1em;
			text-align: left;
			list-style: disc;
			padding-left: 30px;

			&.text-center {
				text-align: center;
			}

			li {
				margin-bottom: 10px;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		ol {
			text-align: center;
			margin-left: 36px;

			li {
				list-style-type: decimal;
			}
		}

		table,
		tr,
		td,
		th {
			vertical-align: middle;
		}

		#participants {
			ul {
				margin: 0;

				li {
					margin-bottom: 30px;

					&::before {
						content: '';
					}

					&:last-child {
						margin-bottom: 30px;
					}
				}
			}
		}
	}
}
